<template>
  <div>
     <b-modal @hidden="resetModal" ref="modal-create-group" size="lg" centered hide-footer  :no-close-on-backdrop="true" content-class="overflow-hidden">
        <template #modal-title>
           <h5 class="text-info mb-0 text-dark">
              <i class="bi bi-credit-card me-1 text-primary"></i> Add UPI Account
           </h5>
        </template>
        <div class="row align-items-top">
            <div class="mb-3 col-12">
              <div class="border-bottom pb-3">
                <label class="text-primary font-size-15">Use Bank Account</label>
                <multiselect v-if="bankAccountList.length" v-model="selectedBankAcc"  @input="updateBankName" :options="bankAccountList" label="keyword" class="font-size-15"  placeholder="Select Bank" track-by="keyword" :close-on-select="true" :allow-empty="false" deselect-label="" select-label="" :show-on-select="true" :disabled="modalLoading" :class="{'is-invalid':modalSubmit && $v.formData.bank_account.$error }"
                >
                <template slot="singleLabel" slot-scope="props">
                    <span class="font-size-14">
                      {{ props.option.keyword }}
                    </span>
                </template>
                <template slot="option" slot-scope="props">
                  <span>
                    {{ props.option.keyword }}
                  </span>
                </template>
                <span slot="noResult">Oops! No bank account found. </span>
                </multiselect>
                <div v-if="modalSubmit && $v.formData.bank_account.$error" class="invalid-feedback">
                  <span v-if="!$v.formData.bank_account.required">Bank account is required.</span>
              </div>
              </div>
              
            </div>
            <div class="mb-3 col-12 col-md-12">
              <label>UPI Name</label>
              <input
                v-model.trim="formData.upi_name"
                type="text"
                class="form-control"
                placeholder="UPI Name"
                name="UPI Name"
                :class="{
                'is-invalid':
                modalSubmit && $v.formData.upi_name.$error,
                }"
                />
              <div
                v-if="modalSubmit && $v.formData.upi_name.$error"
                class="invalid-feedback"
                >
                <span v-if="!$v.formData.upi_name.required"
                    >UPI Name is required.</span
                    >
              </div>
            </div>
            <div class="mb-3 col-12 col-md-12">
              <label>UPI ID</label>
              <input
                v-model.trim="formData.upi_id"
                type="text"
                class="form-control"
                placeholder="UPI ID"
                name="UPI ID"
                :class="{
                'is-invalid':
                modalSubmit && $v.formData.upi_id.$error,
                }"
                />
              <div
                v-if="modalSubmit && $v.formData.upi_id.$error"
                class="invalid-feedback"
                >
                <span v-if="!$v.formData.upi_id.required"
                    >UPI ID is required.</span
                    >
              </div>
            </div>
            <div class="mb-3 col-12 col-md-12 d-none">
              <label class="d-block">Type</label>
              <div>
                <input type="radio" v-model="formData.type" value="UPI" class="btn-check" name="upi_type" id="upi" autocomplete="off">
                <label class="btn btn-outline-primary me-1" :class="{'btn-primary':formData.type=='UPI'}" for="upi">UPI</label>

                <!-- <input type="radio" v-model="formData.type" value="PAYTM"  class="btn-check" name="upi_type" id="paytm" autocomplete="off">
                <label class="btn btn-outline-primary me-1" :class="{'btn-primary':formData.type=='PAYTM'}" for="paytm">PAYTM</label>

                <input type="radio" v-model="formData.type" value="PHONEPE" class="btn-check" name="upi_type" id="phonePe" autocomplete="off">
                <label class="btn btn-outline-primary me-1" :class="{'btn-primary':formData.type=='PHONEPE'}" for="phonePe">PHONEPE</label> -->
              </div>
              <div
                v-if="modalSubmit && $v.formData.type.$error"
                class="invalid-feedback"
                >
                <span v-if="!$v.formData.type.required"
                    >UPI Type is required.</span
                    >
              </div>
            </div>
            <div class="mb-3 col-12 col-md-12">
              <label class="d-block">Transaction Limit 
                <!-- <span class="fw-normal text-muted">({{convertCurrencyFormat(formData.transaction_limit || 0)}})</span> -->
              </label>
              <input
                v-model.trim="formData.transaction_limit"
                type="tel"
                class="form-control"
                placeholder="Transaction Limit"
                name="Transaction Limit"
                :class="{
                'is-invalid':
                modalSubmit && $v.formData.transaction_limit.$error,
                }"
                />
              <div
                v-if="modalSubmit && $v.formData.transaction_limit.$error"
                class="invalid-feedback"
                >
                <span v-if="!$v.formData.transaction_limit.required"
                    >Transaction Limit is required.</span
                    >
              </div>
            </div>
            <div class="mb-3 col-12 col-md-12">
              <label>Remarks</label>
              <div class="input-group">
                <input
                  v-model.trim="formData.remarks"
                  type="text"
                  class="form-control"
                  placeholder="Remarks"
                  name="Remarks"
                />
                <b-dropdown variant="info" right>
                  <template v-slot:button-content>
                    General Remark
                    <i class="mdi mdi-chevron-down"></i>
                  </template>
                  <b-dropdown-item
                    href="javascript:void(0)"
                    :value="value"
                    @click="formData.remarks=value"
                    v-for="(value, index) in remarksList"
                    :key="index"
                    >{{ value }}</b-dropdown-item
                  >
                </b-dropdown>
              </div>
            </div>
            <div class="col-12">
                <hr>
                <div class="d-grid gap-2 d-md-block text-end">
                  <button type="button" class="order-1 btn fw-medium btn-secondary me-md-2" @click="$refs['modal-create-group'].hide()">Cancel</button>
                  <button type="button" class="btn fw-medium btn-info" @click="addRequest" :disabled="modalLoading || loading">
                  <span v-if="modalLoading"> Adding...</span>
                  <span v-else> Add UPI Account </span>
                  <span class="spinner-border spinner-border-sm ms-2" v-if="modalLoading" role="status" aria-hidden="true"></span>
                  </button>
                </div>
            </div>
        </div>
     </b-modal>
     <b-modal @hidden="resetModal" ref="modal-edit-group" size="lg" centered hide-footer  :no-close-on-backdrop="true" content-class="overflow-hidden">
        <template #modal-title>
           <h5 class="text-info mb-0 text-dark">
              <i class="bi bi-credit-card me-1 text-primary"></i> Edit UPI Account
           </h5>
        </template>
        <div class="row align-items-top">
            <div class="mb-3 col-12">
              <div class="border-bottom pb-3">
                <label class="text-primary font-size-15">Use Bank Account</label>
                <multiselect v-if="bankAccountList.length" v-model="selectedBankAcc"  @input="updateBankName" :options="bankAccountList" label="keyword" class="font-size-15"  placeholder="Select Bank" track-by="keyword" :close-on-select="true" :allow-empty="false" deselect-label="" select-label="" :show-on-select="true" :disabled="modalLoading" :class="{'is-invalid':modalSubmit && $v.formEditData.bank_account.$error }"
                >
                <template slot="singleLabel" slot-scope="props">
                    <span class="font-size-14">
                      {{ props.option.keyword }}
                    </span>
                </template>
                <template slot="option" slot-scope="props">
                  <span>
                    {{ props.option.keyword }}
                  </span>
                </template>
                <span slot="noResult">Oops! No bank account found. </span>
                </multiselect>
                <div v-if="modalSubmit && $v.formEditData.bank_account.$error" class="invalid-feedback">
                  <span v-if="!$v.formEditData.bank_account.required">Bank account is required.</span>
              </div>
              </div>
              
            </div>
            <div class="mb-3 col-12 col-md-12">
              <label>UPI Name</label>
              <input
                v-model.trim="formEditData.upi_name"
                type="text"
                class="form-control"
                placeholder="UPI Name"
                name="UPI Name"
                :class="{
                'is-invalid':
                modalSubmit && $v.formEditData.upi_name.$error,
                }"
                />
              <div
                v-if="modalSubmit && $v.formEditData.upi_name.$error"
                class="invalid-feedback"
                >
                <span v-if="!$v.formEditData.upi_name.required"
                    >UPI Name is required.</span
                    >
              </div>
            </div>
            <div class="mb-3 col-12 col-md-12">
              <label>UPI ID</label>
              <input
                v-model.trim="formEditData.upi_id"
                type="text"
                class="form-control"
                placeholder="UPI ID"
                name="UPI ID"
                :class="{
                'is-invalid':
                modalSubmit && $v.formEditData.upi_id.$error,
                }"
                />
              <div
                v-if="modalSubmit && $v.formEditData.upi_id.$error"
                class="invalid-feedback"
                >
                <span v-if="!$v.formEditData.bank_account_no.required"
                    >UPI ID is required.</span
                    >
              </div>
            </div>
            <div class="mb-3 col-12 col-md-12 d-none">
              <label class="d-block">Type</label>
              <div>
                <input type="radio" v-model="formEditData.type" value="UPI" class="btn-check" name="upi_type" id="upi" autocomplete="off">
                <label class="btn btn-outline-primary me-1" :class="{'btn-primary':formEditData.type=='UPI'}" for="upi">UPI</label>

                <!-- <input type="radio" v-model="formEditData.type" value="PAYTM"  class="btn-check" name="upi_type" id="paytm" autocomplete="off">
                <label class="btn btn-outline-primary me-1" :class="{'btn-primary':formEditData.type=='PAYTM'}" for="paytm">PAYTM</label>

                <input type="radio" v-model="formEditData.type" value="PHONEPE" class="btn-check" name="upi_type" id="phonePe" autocomplete="off">
                <label class="btn btn-outline-primary me-1" :class="{'btn-primary':formEditData.type=='PHONEPE'}" for="phonePe">PHONEPE</label> -->
              </div>
              <div
                v-if="modalSubmit && $v.formEditData.type.$error"
                class="invalid-feedback"
                >
                <span v-if="!$v.formEditData.type.required"
                    >UPI Type is required.</span
                    >
              </div>
            </div>
            <div class="mb-3 col-12 col-md-12">
              <label class="d-block">Transaction Limit 
                <!-- <span class="fw-normal text-muted">({{convertCurrencyFormat(formData.transaction_limit || 0)}})</span> -->
              </label>
              <input
                v-model.trim="formEditData.transaction_limit"
                type="tel"
                class="form-control"
                placeholder="Transaction Limit"
                name="Transaction Limit"
                :class="{
                'is-invalid':
                modalSubmit && $v.formEditData.transaction_limit.$error,
                }"
                />
              <div
                v-if="modalSubmit && $v.formEditData.transaction_limit.$error"
                class="invalid-feedback"
                >
                <span v-if="!$v.formEditData.transaction_limit.required"
                    >Transaction Limit is required.</span
                    >
              </div>
            </div>
            <div class="mb-3 col-12">
              <div class="align-items-center">
                <label class="mb-1 me-2">Status</label>
                <div>
                  <div v-if="selectedBankAcc.active==0" class="form-check form-switch form-check-custom ms-1 mb-0 font-size-14">
                    <input class="form-check-input" type="checkbox" role="switch"  :disabled="true">
                  </div>
                  <div v-else class="form-check form-switch form-check-custom ms-1 mb-0 font-size-14">
                    <input class="form-check-input" type="checkbox" role="switch" v-model="formEditData.status" true-value="1" false-value="0" :disabled="modalLoading || (selectedBankAcc.active==0) ">
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-3 col-12 col-md-12">
              <label>Remarks</label>
              <div class="input-group">
                <input
                  v-model.trim="formEditData.remarks"
                  type="text"
                  class="form-control"
                  placeholder="Remarks"
                  name="Remarks"
                />
                <b-dropdown variant="info" right>
                  <template v-slot:button-content>
                    General Remark
                    <i class="mdi mdi-chevron-down"></i>
                  </template>
                  <b-dropdown-item
                    href="javascript:void(0)"
                    :value="value"
                    @click="formEditData.remarks=value"
                    v-for="(value, index) in remarksList"
                    :key="index"
                    >{{ value }}</b-dropdown-item
                  >
                </b-dropdown>
              </div>
            </div>
            <div class="col-12">
                <hr>
                <div class="d-grid gap-2 d-md-block text-end">
                  <button type="button" class="order-1 btn fw-medium btn-secondary me-md-2" @click="$refs['modal-edit-group'].hide()">Cancel</button>
                  <button type="button" class="btn fw-medium btn-info" @click="updateRequest" :disabled="modalLoading || loading">
                  <span v-if="modalLoading"> Updating...</span>
                  <span v-else> Update </span>
                  <span class="spinner-border spinner-border-sm ms-2" v-if="modalLoading" role="status" aria-hidden="true"></span>
                  </button>
                </div>
            </div>
        </div>
     </b-modal>
     <b-modal @hidden="resetModal" ref="modal-details" size="lg" centered hide-footer content-class="overflow-hidden">
        <template #modal-title>
           <h5 class="text-info mb-0 text-dark">
              <i class="bi bi-credit-card me-1 text-primary"></i> {{ updateData.upi_name }} - Today Transactions / Limit
           </h5>
        </template>
        <div class="row align-items-center">
          <div class="col-12">
            <div class="d-flex">
                <div class="app-search2 position-relative">
                  <input
                    type="search"
                    placeholder="Filter..."
                    class="form-control mb-3"
                    v-model.trim="searchKey"
                    autocomplete="no"
                    @keyup="filterByGroup()"
                  />
                  <span class="uil-search text-info"></span>
                </div>
                <button
                  class="btn btn-light mb-3 ms-2 text-nowrap"
                  type="button"
                  @click="searchKey=''"
                >
                  <i class="uil-redo me-1"></i> Reset
              </button>
            </div>
          </div>
          <div class="col-12">
            <div
              class="table-responsive text-nowrap font-size-14 position-relative"
            >
            <table class="table align-middle mb-0" :class="{'':filterByGroup().length>0 && !modalLoading}">
              <thead>
                <tr>
                  <th scope="col" class="fw-medium">Group Name</th>
                  <th scope="col" class="fw-medium">Today Transaction</th>
                  <th scope="col" class="fw-medium text-center">Transaction Limit</th>
                </tr>
              </thead>
              <tbody class="border-bottom">
                <tr v-for="(value, index) in filterByGroup()" :key="index">
                  <td>{{ value.name }}</td>
                  <td>{{ convertCurrencyFormat(value.transaction_spent_in_group|| 0) }}</td>
                  <td class="text-center border-start bg-light" v-if="index === 0" :rowspan="filterByGroup().length">
                    {{ convertCurrencyFormat(updateData.transaction_limit|| 0)  }}
                   </td>
                </tr>
                <tr v-if="!filterByGroup().length && !loading && searchKey">
                  <td colspan="3" height="200" class="text-center text-muted">
                    <i class="bi bi-credit-card me-1"></i>  Sorry, we couldn't
                    find any results of "{{ searchKey }}".
                  </td>
                </tr>
                <tr v-if="!filterByGroup().length && !loading && !searchKey">
                  <td colspan="3" height="200" class="text-center text-muted">
                    <i class="bi bi-credit-card me-1"></i>  No Data Avavilable
                  </td>
                </tr>
              </tbody>
            </table>
            </div>
            <div class="p-2 mt-2">Total Lifetime Volume:
              <span v-if="updateData.transaction_spent_total">
                {{ convertCurrencyFormat(updateData.transaction_spent_total|| 0)  }}
              </span>
            </div>
          </div>
         
          <div class="col-12">
              <hr>
              <div class="d-grid gap-2 d-md-block text-end">
                <button type="button" class="order-1 btn fw-medium btn-secondary me-md-2" @click="$refs['modal-details'].hide()">Cancel</button>
              </div>
          </div>
        </div>
     </b-modal>
     <Common ref="commonFunc" />
  </div>
</template>

<script>
  import appConfig from "@/app.config";
  import axios from "axios";
  import Swal from "sweetalert2";
  import Common from "@/components/common";
  import Multiselect from "vue-multiselect";
  import { 
    required, 
    //minLength, 
    //url
  } from "vuelidate/lib/validators";

  /**
   * Starter page
   */
  const BankDefault = {};
  export default {
    components: {
      Common,
      Multiselect
    },
    props: {
      data: {
        type: Object,
        required: true,
      }
    },
    data() {
      return {
        updateData: {
          preview_username:""
        },
        accessToken: '',
        accessEmail: '',
        accessPhone: '',
        accessUsername: '',
        account_type: '',
        create_modify_permission:false,
        loading: false,
        modalSubmit: false,
        modalLoading: false,
        returnData: [],
        returnData2: [],
        isEdit:false,
        bankAccountList:[],
        remarksList:["司法冻结","送回维护","银行冻结","其它"],
        selectedBankAcc: BankDefault,
        searchKey:"",
        formData: {
          bank_account:"",
          upi_name:"",
          upi_id:"",
          type:"UPI",
          transaction_limit: 90000,
          remarks:"",
        },
        formEditData: {
          id:"",
          bank_account:"",
          upi_name:"",
          upi_id:"",
          type:"UPI",
          status:"",
          remarks:"",
        },
        currentModules:[]
      };
    },
    validations: {
      formData: {
        bank_account: {
          required,
        },
        upi_name: {
          required,
        },
        upi_id: {
          required,
        },
        type: {
          required,
        },
        transaction_limit:{
          required
        }
      },
      formEditData: {
        bank_account: {
          required,
        },
        upi_name: {
          required,
        },
        upi_id: {
          required,
        },
        type: {
          required,
        },
        transaction_limit:{
          required
        }
      },

    },
    middleware: "authentication",
    async mounted() {
      this.reload();
    },
    created() {
      
    },
    methods: {
      getBankAccountList() {
        console.log("getBankAccountList")
        this.$Progress.start();
        this.loading = true
        this.disabled = true
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername", this.accessUsername);
        bodyFormData.append("page", "1");
        bodyFormData.append("limit", "99999");
        bodyFormData.append("keyword", "");
        bodyFormData.append("sortBy", "status_asc");
        axios({
            method: "post",
            url: appConfig.APIHostAdmin + "controller/bankAccount/getAllBankAccountList",
            data: bodyFormData,
            headers: {
              "Content-Type": "multipart/form-data"
            },
          }).then((response) => {
          var resData = response.data;
          console.log(resData.result)
          if (resData.status == 200) {
            this.selectedBankAcc = BankDefault
            this.bankAccountList = []
            var selectedBank2 = {}
            resData.data.forEach(element => {
              this.bankAccountList.push(
                {
                  account_db_code: element.account_db_code,
                  id: element.id,
                  currency: element.currency,
                  bank_name: element.bank_name,
                  bank_account_name: element.bank_account_name,
                  bank_account_no: element.bank_account_no,
                  ifsc_code: element.ifsc_code,
                  remarks: element.remarks,
                  active: element.active,
                  keyword:`${element.bank_name} - ${element.bank_account_no} (${element.bank_account_name})`
                }
              );
              console.log(this.formEditData.bank_account)
              console.log(this.formEditData.bank_account.account_db_code)
              console.log(element.account_db_code)
              if (this.formEditData.bank_account.account_db_code==element.account_db_code){
                selectedBank2 = {
                  account_db_code: element.account_db_code,
                  id: element.id,
                  currency: element.currency,
                  bank_name: element.bank_name,
                  bank_account_name: element.bank_account_name,
                  bank_account_no: element.bank_account_no,
                  ifsc_code: element.ifsc_code,
                  remarks: element.remarks,
                  active: element.active,
                  keyword:`${element.bank_name} - ${element.bank_account_no} (${element.bank_account_name})`
                }
              }
            });
            if (this.isEdit){
              console.log("selectedBank2 ")
              console.log(selectedBank2)
              this.selectedBankAcc = selectedBank2
              this.formEditData.bank_account = selectedBank2
            }else{
              if (this.selectedBankAcc==BankDefault){
                 this.selectedBankAcc = this.bankAccountList[0];
              }
              this.formData.bank_account = this.selectedBankAcc
              this.formData.upi_name = this.selectedBankAcc.bank_account_name;
              this.bankAccountList = this.bankAccountList.filter(e => e.active==1)
            }
          } else if (resData.status == 440) {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              html: `${resData.message}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
            localStorage.clear();
            this.$router.push({
              path: "/login",
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              html: `${resData.message}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
          }
          this.loading = false;
          this.disabled = false
          this.$Progress.finish();
        }).catch((error) => {
          this.loading = false;
          this.disabled = false
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            html: `${error}.`,
            confirmButtonColor: '#222',
            confirmButtonText: this.$t('siteLang.Done'),
          })
        });
      },
      filterByGroup() {
        return this.returnData.filter((e) => {
            return (
            e.name.toLowerCase().includes(this.searchKey.toLowerCase()) 
            );
        })
      },
      inputNumberOnly(event, parentModel, childModel) {
        const numericValue = event.target.value.replace(/[^0-9.]/g, '');
        this[parentModel][childModel] = numericValue
      },
      inputNumberOnlyV2(event, parentModel, childModel) {
        var numericValue = event.target.value.replace(/[^0-9.]/g, '');
        this[parentModel][childModel] = numericValue
        if(numericValue.length > 2){
          var val = numericValue.replace(/[^0-9]/g, '');
          console.log(val);
          val = val.substr(0, val.length-2)+"."+val.substr(-2);
          this[parentModel][childModel] = val;
        }
      },
      inputNumberOnlyV3(event, parentModel, childModel, index) {
        var numericValue = event.target.value.replace(/[^0-9.]/g, '');
        //this[parentModel][childModel] = numericValue
        this[parentModel].contract_selected[index][childModel] = numericValue
        if(numericValue.length > 2){
          var val = numericValue.replace(/[^0-9]/g, '');
          console.log(val);
          val = val.substr(0, val.length-2)+"."+val.substr(-2);
          this[parentModel].contract_selected[index][childModel] = val;
        }
      },
      convertCurrencyFormat(value, show00) {
        if (show00 == true) {
          return this.$refs.commonFunc.convertCurrencyFormat(value)
        } else {
          // Assuming this.transactionLimit is defined in your component's data
          return this.$refs.commonFunc.convertCurrencyFormat(value).replaceAll(".00", "");
        }
      },
      showModal() {
        this.$refs['modal-create-group'].show()
        this.accessToken = this.data.accessToken;
        this.accessUsername = this.data.accessUsername;
        this.isEdit = false;
        this.getBankAccountList();
      },
      showEditModal() {
        this.$refs['modal-edit-group'].show()
        this.accessToken = this.data.accessToken;
        this.accessUsername = this.data.accessUsername;
        this.updateData = this.data.updateData;
        this.isEdit = true;
        // const bankAccounts = this.data.updateData.bank_account_linked;
        // bankAccounts.keyword=`${bankAccounts.bank_name} - ${bankAccounts.bank_account_no} (${bankAccounts.bank_account_name})`

        this.formEditData={
          id: this.data.updateData.account_db_code,
          bank_account: this.data.updateData.bank_account_linked,
          upi_name: this.data.updateData.upi_name,
          upi_id: this.data.updateData.upi_id,
          type: this.data.updateData.type,
          transaction_limit: this.data.updateData.transaction_limit,
          status: this.data.updateData.active,
          remarks: this.data.updateData.remarks,
        }
        this.getBankAccountList();
      },
      updateBankName(){
        this.formData.bank_account = this.selectedBankAcc;
        this.formEditData.bank_account = this.selectedBankAcc;
        this.formData.upi_name=this.selectedBankAcc.bank_account_name;
        this.formEditData.upi_name=this.selectedBankAcc.bank_account_name
        if (this.selectedBankAcc.active==1){
          this.formEditData.status = 1
        }else{
          this.formEditData.status = 0
        }
        
      },
      showDetails() {
        this.$refs['modal-details'].show()
        this.accessToken = this.data.accessToken;
        this.accessUsername = this.data.accessUsername;
        this.updateData = this.data.updateData;
        this.returnData = this.updateData.group_linked
      },
      showDetails2() {
        this.$refs['modal-merchants'].show()
        this.accessToken = this.data.accessToken;
        this.accessUsername = this.data.accessUsername;
        this.updateData = this.data.updateData;
        this.returnData2 = this.updateData.merchants
      },
      resetModal() {
        this.searchKey = "";
        this.returnData = []
        this.returnData2 = []
        this.modalSubmit = false;
        this.modalLoading = false;
        this.formData={
          bank_account:"",
          upi_name:"",
          upi_id:"",
          type:"UPI",
          transaction_limit:90000,
          remarks:"",
        },
        this.formEditData={
          id:"",
          bank_account:"",
          upi_name:"",
          upi_id:"",
          type:"",
          transaction_limit:"",
          status:"",
          remarks:"",
        }
      },
      addRequest(){
        this.modalSubmit = true
        this.$v.formData.$touch();
        console.log((this.$v.formData.$invalid))
        if (this.$v.formData.$invalid) {
          return;
        }
        else {
          this.$Progress.start();
          this.modalLoading = true;
          var bodyFormData = new FormData();

          bodyFormData.append("accessToken", this.accessToken);
          bodyFormData.append("accessUsername", this.accessUsername);
          bodyFormData.append("bankAccount", JSON.stringify(this.formData.bank_account));
          bodyFormData.append("upiName", this.formData.upi_name);
          bodyFormData.append("upiId", this.formData.upi_id);
          bodyFormData.append("type", this.formData.type);
          bodyFormData.append("transactionLimit", this.formData.transaction_limit);
          bodyFormData.append("remarks", this.formData.remarks);
          axios({
              method: "post",
              url: appConfig.APIHostAdmin + 'controller/upiAccount/addUpiAccount',
              data: bodyFormData,
              headers: { "Content-Type": "multipart/form-data" },
          })
          .then((response)=>{
              
              var resData = response.data;
              if (resData.status == 200) {
                console.log(resData)
                Swal.fire({
                  icon: 'success',
                  title: 'UPI Account Successfully Added',
                  html: 'Your UPI account has been successfully added!',
                  confirmButtonColor: '#FA6541',
                  confirmButtonText: this.$t('siteLang.Done')
                }).then(() => {
                  this.$refs['modal-create-group'].hide()
                  this.$emit('callParentFunction');
                })
                
              } 
              else if (resData.status == 440){
                Swal.fire(
                    {
                    icon: 'error',
                    title: 'Oops...',
                    html: `${resData.message}.`,
                    confirmButtonColor: '#222',
                    confirmButtonText: this.$t('siteLang.Done'),
                  })
                  localStorage.clear();
                  this.$router.push({
                      path: "/login",
                });
              }
              else {
                  Swal.fire(
                    {
                    icon: 'error',
                    title: 'Oops...',
                    html: `${resData.message}.`,
                    confirmButtonColor: '#222',
                    confirmButtonText: this.$t('siteLang.Done'),
                  })
              }
              this.modalLoading = false;
              this.modalSubmit = false
              this.$Progress.finish();
            })
            .catch((error)=> {
                this.modalLoading = false;
                this.modalSubmit = false
                this.$Progress.finish();
                //Swal.fire("Error", error, "error");
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${error}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
          });
        }
      },
      updateRequest(){
        this.modalSubmit = true
        this.$v.formEditData.$touch();
        console.log((this.$v.formEditData.$invalid))

        // const combinedArray = this.formEditData.bank_accounts.concat(this.formEditData.bank_accounts_new);

        if (this.$v.formEditData.$invalid) {
          return;
        }
        else {
          this.$Progress.start();
          this.modalLoading = true;
          var bodyFormData = new FormData();
          
          bodyFormData.append("accessToken", this.accessToken);
          bodyFormData.append("accessUsername", this.accessUsername);
          bodyFormData.append("accountDbCode", this.formEditData.id);
          bodyFormData.append("bankAccount", JSON.stringify(this.formEditData.bank_account));
          bodyFormData.append("upiName", this.formEditData.upi_name);
          bodyFormData.append("upiId", this.formEditData.upi_id);
          bodyFormData.append("type", this.formEditData.type);
          bodyFormData.append("transactionLimit", this.formEditData.transaction_limit);
          bodyFormData.append("remarks", this.formEditData.remarks);
          bodyFormData.append("status", this.formEditData.status);
          axios({
              method: "post",
              url: appConfig.APIHostAdmin + 'controller/upiAccount/updateUpiAccount',
              data: bodyFormData,
              headers: { "Content-Type": "multipart/form-data" },
          })
          .then((response)=>{
              
              var resData = response.data;
              if (resData.status == 200) {
                console.log(resData)
                Swal.fire({
                  icon: 'success',
                  title: 'UPI Account Updated',
                  html: 'UPI account has been successfully updated!',
                  confirmButtonColor: '#FA6541',
                  confirmButtonText: this.$t('siteLang.Done')
                }).then(() => {
                  this.$refs['modal-edit-group'].hide()
                  this.$emit('callParentFunction');
                })
                
              } 
              else if (resData.status == 440){
                Swal.fire(
                    {
                    icon: 'error',
                    title: 'Oops...',
                    html: `${resData.message}.`,
                    confirmButtonColor: '#222',
                    confirmButtonText: this.$t('siteLang.Done'),
                  })
                  localStorage.clear();
                  this.$router.push({
                      path: "/login",
                });
              }
              else {
                  Swal.fire(
                    {
                    icon: 'error',
                    title: 'Oops...',
                    html: `${resData.message}.`,
                    confirmButtonColor: '#222',
                    confirmButtonText: this.$t('siteLang.Done'),
                  })
              }
              this.modalLoading = false;
              this.modalSubmit = false
              this.$Progress.finish();
            })
            .catch((error)=> {
                this.modalLoading = false;
                this.modalSubmit = false
                this.$Progress.finish();
                //Swal.fire("Error", error, "error");
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${error}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
          });
        }
      },
      updateUPI(value){
        this.$Progress.start();
        this.modalLoading = true
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername",  this.accessUsername);
        bodyFormData.append("accountDbCode", this.updateData.account_db_code);
        bodyFormData.append("id", value.id);
        bodyFormData.append("active", value.active);
        axios({
            method: "post",
            //url: appConfig.APIHostAdmin + 'controller/activeInactiveUpiAccount', 
            url: appConfig.DemoAPI,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
          var resData = response.data;
          //this.returnData = []
          if (resData.status == 200) {
            console.log(resData)
            value.active = (value.active==1)? 1 : 0;
            //this.getData();
          } 
          else if (resData.status == 440){
            value.active = (value.active==1)? 0 : 1;
            Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
              localStorage.clear();
              this.$router.push({
                  path: "/login",
            });
          }
          else {
              Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
          }
          this.modalLoading = false;
          this.$Progress.finish();
        })
        .catch((error)=> {
            this.modalLoading = false;
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire(
              {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
      });
    },
    async reload() {
        try {
          await this.$nextTick(); // Wait for the component to be fully mounted
          const commonFunc = this.$refs.commonFunc;
          if (commonFunc && commonFunc.getFullData) {
            const data = await commonFunc.getFullData();
            this.account_type = data.account_type;
            const permission = data.modules.filter(e => e.name==='administrator')
            if (permission) {
              this.create_modify_permission = permission[0].create_modify_permission==0 ? false : true; 
              console.log(this.create_modify_permission)
            } else {
              console.log('Module with name "administrator" not found.');
            }
          } else {
            // Handle the case when commonFunc or getFullData is undefined
            // Perform appropriate actions or show a fallback message to the user
          }
        } catch (error) {
          // Handle the error silently without propagating it further
        }
      },
    }
  };
</script>
<style scoped>
  label{
    margin-bottom: 5px;
  }
  .custom-table td .row{
    min-width:220px;
    
  }
</style>